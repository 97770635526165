import { Button, ColorType, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useEffect, useState } from "react";
import { Category, CategoryTreeElem } from "../models/Category";
import { CategorySearch } from "./CategorySearch";
import { CategoryTreeSelector } from "./CategoryTreeSelector";

export interface UpdateCategoryFormProps {
  category: { category: Category; parent?: CategoryTreeElem }; // CategoryTreeElem ?
  categories: CategoryTreeElem[];
  onFinish: (category: Category) => void;
}

export const UpdateCategoryForm: FunctionComponent<UpdateCategoryFormProps> = ({ category, categories, onFinish }) => {
  const [input, setInput] = useState<string>("");
  const [parentCategory, setParentCategory] = useState<CategoryTreeElem>();

  useEffect(() => {
    if (category) {
      setInput(category.category.title);
      setParentCategory(category.parent);
    }
  }, [category]);

  return (
    <div>
      <div className="bg-white">
        <div className="text-xl font-bold mb-10 text-center">{category.category.title}</div>
        <CategoryTreeSelector
          title="Catégorie parente"
          categories={categories}
          onChange={setParentCategory}
          value={parentCategory}
        />
        <TextInput
          label="Nom de la catégorie"
          className="mt-5"
          value={input}
          onChange={({ target }) => setInput(target.value)}
          placeholder="Nom de la catégorie"
          light
          type="text"
        />
        <div className="flex justify-end mt-10">
          <Button
            title="Confirmer"
            disabled={input.length === 0}
            onClick={() => onFinish({ ...category.category, title: input, parent_id: parentCategory?.category.id })}
            colorType={ColorType.Content}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};
