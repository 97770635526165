import { toast } from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES, { Uuid } from "../services/routes";
import { JobTitleResponse } from "./JobTitle";

export interface Category {
  id: string;
  title: string;
  parent_id?: string; // {uuid: true}
}

export interface NewCategory {
  title: string;
  parent_id?: string;
}

export interface CategoryTreeElem {
  category: Category;
  jobTitles?: JobTitleResponse[];
  childs: CategoryTreeElem[];
}

export const constructCategoryTree = (categories: Category[], jobTitles?: JobTitleResponse[]) => {
  const jobTitlesMap = jobTitles?.reduce((acc, next) => {
    (acc[next.category_id] = acc[next.category_id] || []).push(next);
    return acc;
  }, {} as { [key: Uuid]: JobTitleResponse[] });

  return categories
    .filter((elem) => !elem.parent_id)
    .map((elem: Category): CategoryTreeElem => {
      const constructFromParent = (parent: Category): CategoryTreeElem => {
        let childs = categories.filter((category) => category.parent_id === parent.id);
        return {
          jobTitles: jobTitlesMap?.[parent.id],
          category: parent,
          childs: childs.map(constructFromParent),
        };
      };
      return constructFromParent(elem);
    });
};

export const createCategory = (category: NewCategory) =>
  httpClient.req(ROUTES.CREATE_CATEGORY(category)).then((res) => {
    toast.success("La catégorie a été créée");
    return res;
  });
export const deleteCategory = (id: Uuid) =>
  httpClient.req(ROUTES.DELETE_CATEGORY(id)).then((res) => {
    toast.success("La catégorie a été suprimée");
    return res;
  });
export const updateCategory = (category: Category) =>
  httpClient.req(ROUTES.UPDATE_CATEGORY(category)).then((res) => {
    toast.success("La catégorie a été mise à jours");
    return res;
  });
export const fetchCategories = () => httpClient.req(ROUTES.FETCH_CATEGORIES());
export const getCategoryPath = (id: Uuid) => httpClient.req(ROUTES.GET_CATEGORY_PATH(id));
export const migrateCategory = (from_id: Uuid, to_id: Uuid) =>
  httpClient.req(ROUTES.MIGRATE_CATEGORY(from_id, to_id)).then((res) => {
    toast.success("La catégorie à été migrée");
  });
