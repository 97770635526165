import { ModalPortal } from "@getprorecrutement/getpro-design";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ChangeRecordsPage } from "./pages/ChangeRecords";
import JobTreePage from "./pages/JobTree";
import { Login } from "./pages/Login";
import { StatsPage } from "./pages/Stats";
import { Test } from "./pages/Test";
import { ValidationPage } from "./pages/Validation";
import store, { Message, Stored } from "./services/store";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { SkillsPage } from "./pages/Skills";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale("fr");

function App() {
  const [authed, setAuthed] = useState<boolean>(!!store.state.RefreshToken);

  useEffect(() => store.listen(Message.NeedAuth, () => setAuthed(false)), []);
  useEffect(() => store.listen(Stored.RefreshToken, (e) => setAuthed(!!e)), []);

  return (
    <div className="min-h-screen flex flex-col">
      <Toaster />
      <ModalPortal />
      {authed ? (
        <Router>
          <Routes>
            <Route path="/" element={<JobTreePage />} />
            <Route path="/validation" element={<ValidationPage />} />
            <Route path="/records" element={<ChangeRecordsPage />} />
            <Route path="/test" element={<Test />} />
            <Route path="/stats" element={<StatsPage />} />
            <Route path="/skills" element={<SkillsPage />} />
          </Routes>
        </Router>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
