import { TextInput, Button } from "@getprorecrutement/getpro-design";
import { FC, useState } from "react";
import { login } from "../models/auth";
import { loadable } from "../services/httpClient";
import store from "../services/store";

export const Login: FC<{}> = () => {
  const [mail, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="w-screen h-screen bg-primary-darker flex items-center justify-center">
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setLoading(true);
          loadable(
            login({ email: mail, password }).then((d) => store.setCredentials(d)),
            setLoading
          );
        }}
        className="text-white rounded-3xl bg-primary-dark p-5"
      >
        <p className="font-bold text-xl ml-5">Connection</p>
        <div className="m-5 p-5 bg-primary-dark border-dashed border-2 border-primary-normal rounded-3xl">
          <div className="bg-inherit">
            <TextInput
              type="email"
              size="large"
              placeholder="Email"
              label="Email"
              value={mail}
              onChange={({ target }) => {
                setMail(target.value);
              }}
            />
          </div>
          <div className="mt-5 bg-inherit">
            <TextInput
              type="password"
              size="large"
              placeholder="Mot de passe"
              label="Mot de passe"
              value={password}
              onChange={({ target }) => {
                setPassword(target.value);
              }}
            />
          </div>
        </div>
        <div className="float-right mr-5">
          <Button type={"submit"} loading={loading} title="Se connecter" />
        </div>
      </form>
    </div>
  );
};
