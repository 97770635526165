import { FunctionComponent, useEffect, useState } from "react";
import { getStatForJobTitle, getStats, TestAggregated, TestAttemptStat } from "../models/testAttempt";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Uuid } from "../services/routes";
import { Modal, Table } from "@getprorecrutement/getpro-design";

export const StatsPage: FunctionComponent<{}> = () => {
  const [testAttemptStats, setTestAttemptStats] = useState<TestAttemptStat[]>();
  const [selectedAttempStat, setSelectedAttempStat] = useState<TestAttemptStat>();
  const [aggregatedTests, setAggregatedTests] = useState<TestAggregated[]>();

  useEffect(() => {
    getStats().then((res) => {
      setTestAttemptStats(res);
    });
  }, []);

  const select = async (testStat: TestAttemptStat) => {
    setAggregatedTests(await getStatForJobTitle(testStat.job_title_id));
    setSelectedAttempStat(testStat);
  };

  const reset = () => {
    setAggregatedTests(undefined);
    setSelectedAttempStat(undefined);
  };

  return (
    <div className="p-20">
      <div className="text-2xl">Pourcentage de réussite au test de catégorisation (du pire au meilleur): </div>
      <div className="flex flex-wrap gap-3 mt-10">
        {testAttemptStats?.map((test) => {
          return (
            <div
              className="p-5 shadow-md border border-primary-lighter text-primary-darker text-xs rounded-xl w-64 cursor-pointer"
              key={test.job_title_id}
              onClick={() => select(test)}
            >
              <div className="w-48 h-48">
                <CircularProgressbar
                  styles={buildStyles({
                    textSize: 12,
                    textColor: "#334155",
                    backgroundColor: "#334155",
                    pathColor: "#334155",
                    trailColor: "#cbd5e1",
                  })}
                  value={test.sucess_percentage * 100}
                  className="font-xs"
                  text={`${(test.sucess_percentage * 100).toFixed(0)} %`}
                />
              </div>

              <div className="text-xl text-center mt-5 text-primary-dark">{test.job_title_name}</div>
            </div>
          );
        })}
      </div>

      <Modal onClose={reset} show={selectedAttempStat !== undefined && aggregatedTests !== undefined}>
        <div>
          <div className="text-xl text-center">{selectedAttempStat?.job_title_name}</div>
          <div className="w-[700px] mt-5 flex flex-col gap-3">
            <div className="flex justify-between font-bold mb-2">
              <div>Catégorie sélectionnées</div>
              <div>Nombre d'occurence</div>
            </div>
            {aggregatedTests?.map((test, index) => {
              return (
                <div className="flex justify-between" key={index}>
                  <div>{test.sent_category_path}</div>
                  <div>{test.count}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};
