import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import logoIcon from "../assets/logo.svg";
export enum Page {
  Validation,
  Title,
}

export interface HeaderProps {
  center?: JSX.Element;
  left?: JSX.Element;
}

export const Header: FunctionComponent<HeaderProps> = ({ center, left }) => {
  return (
    <div className="h-32 bg-black w-full flex justify-between items-center px-10 py-5">
      <div className="w-1/3 flex justify-start">
        <img src={logoIcon} alt="logo" />
      </div>
      <div className="w-1/3 flex justify-center">{center || <div></div>}</div>
      <div className="w-1/3 flex justify-end">{left || <div></div>}</div>
    </div>
  );
};
