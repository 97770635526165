import { toast } from "react-hot-toast";
import { Route } from "react-router-dom";
import httpClient from "../services/httpClient";
import ROUTES, { Uuid } from "../services/routes";
import { Category } from "./Category";
import { JobTitle, JobTitleResponse, JobTitleUpdate, UnvalidatedJobTitle, UnvalidatedJobTitleUpdate } from "./JobTitle";
import { PaginationParams } from "./pagination";

export interface UpdateUnvalidateJobTitleAction {
  from: UnvalidatedJobTitle;
  to: UnvalidatedJobTitleUpdate;
}

export interface AssignUnvalidatedJobTitleAction {
  old: WithPath<UnvalidatedJobTitle>;
  new: WithPath<JobTitle>;
}

export interface UpdateJobTitleAction {
  from: JobTitle;
  to: JobTitleUpdate;
}

export interface AssignJobTitleAction {
  old: WithPath<JobTitle>;
  new: WithPath<JobTitle>;
}

export interface WithPath<B> {
  item: B;
  path: Category[];
}

export enum ActionTypes {
  Delete = "Delete",
  Update = "Update",
  Validate = "Validate",
  Assign = "Assign",
}

export interface UnvalidatedJobTitleAction {
  [ActionTypes.Delete]?: UnvalidatedJobTitle;
  [ActionTypes.Update]?: UpdateUnvalidateJobTitleAction;
  [ActionTypes.Validate]?: UnvalidatedJobTitle;
  [ActionTypes.Assign]?: AssignUnvalidatedJobTitleAction;
}

export interface JobTitleAction {
  [ActionTypes.Delete]?: JobTitle;
  [ActionTypes.Update]?: UpdateJobTitleAction;
  [ActionTypes.Validate]: undefined;
  [ActionTypes.Assign]?: AssignJobTitleAction;
}

export interface ChangeRecordAction {
  UnvalidatedJobTitle?: UnvalidatedJobTitleAction;
  JobTitle?: JobTitleAction;
}

export interface ChangeRecordFilter {
  email?: string;
}

export interface ChangeRecord {
  id: Uuid;
  user_email: string;
  action: ChangeRecordAction;
  created_at: string;
  item_ids: Uuid[];
}

export const getKind = (record: ChangeRecord): ActionTypes => {
  let action = getAction(record);
  console.log(action);
  for (let entry of Object.entries(action)) {
    if (!!entry[1]) return entry[0] as ActionTypes;
  }
  throw "Action not found in ActionTypes";
};

export const isValidated = (record: ChangeRecord): boolean => {
  return !!record.action.JobTitle;
};

export const getAction = (record: ChangeRecord): UnvalidatedJobTitleAction | JobTitleAction => {
  if (record.action.JobTitle) return record.action.JobTitle;
  if (record.action.UnvalidatedJobTitle) return record.action.UnvalidatedJobTitle;

  throw "Unrecognised ChangeRecord action";
};

export const getChangeRecords = (filters?: ChangeRecordFilter & PaginationParams) =>
  httpClient.req(ROUTES.GET_CHANGE_RECORDS(filters));
export const deleteChangeRecord = (id: Uuid) =>
  httpClient.req(ROUTES.DELETE_CHANGE_RECORDS(id)).then((res) => {
    toast.success("Le record a bien été supprimé");
    return res;
  });
export const revertChangeRecord = (id: Uuid) =>
  httpClient.req(ROUTES.REVERT_CHANGE_RECORDS(id)).then((res) => {
    toast.success("Le changement a été annulé");
    return res;
  });
