import { Select, TextInput } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { CategoryTreeElem } from "../models/Category";
import { CategoryTree } from "./CategoryTree";

export interface CategoryTreeSelectorProps {
  categories: CategoryTreeElem[];
  value?: CategoryTreeElem;
  title?: string;
  onChange: (category: CategoryTreeElem) => void;
}

export const CategoryTreeSelector: FunctionComponent<CategoryTreeSelectorProps> = ({
  categories,
  value,
  title,
  onChange,
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [filteredCategories, setFilteredCategories] = useState<CategoryTreeElem[]>();

  const filterCategories = useCallback(
    (categoryTree: CategoryTreeElem[]): CategoryTreeElem[] => {
      let filtered = categoryTree.reduce((acc, next) => {
        let filtered_category = { ...next, childs: filterCategories(next.childs) };
        if (
          filtered_category.category.title.toLowerCase().includes(searchInput.toLowerCase() || "") ||
          filtered_category.childs.length !== 0
        )
          acc.push(filtered_category);
        return acc;
      }, [] as CategoryTreeElem[]);
      return filtered;
    },
    [searchInput]
  );

  useEffect(() => {
    if (value) {
      setSearchInput(value.category.title);
    }
  }, [value]);

  useEffect(() => {
    setFilteredCategories(filterCategories(categories));
  }, [filterCategories, categories]);

  const renderSelectValue = (opened: boolean, _: any) => {
    const textInputClasses = classNames("  ", {
      "border-b-0 rounded-b-none": opened,
    });
    const valueClasses = classNames("border overflow-hidden  hover:border-primary-dark rounded-full px-2", {
      "border-b-0 rounded-b-none rounded-2xl border-primary-medium":
        opened && filteredCategories && filteredCategories.length > 0,
      "border-primary-light": !opened || !filteredCategories?.length,
    });

    return (
      <div className={valueClasses} onClick={(ev) => opened && ev.stopPropagation()}>
        <TextInput
          className={textInputClasses}
          bordered={false}
          placeholder="Recherchez une catégorie"
          onChange={({ target }) => setSearchInput(target.value)}
          value={searchInput}
          type="text"
        />
      </div>
    );
  };

  return (
    <Select
      bordered
      valueRenderer={renderSelectValue}
      rounded
      className="bg-white"
      type="single"
      options={filteredCategories || []}
      value={value}
      onChange={() => {}}
      label={title || "Catégorie"}
      getKey={(elem) => elem.category.id}
      optionRenderer={(category, index, onClick) => (
        <CategoryTree
          key={index}
          selectedParent={undefined}
          category={category}
          onClick={(elem) => {
            onClick();
            onChange(elem);
          }}
        />
      )}
      getLabel={(elem) => {
        return elem.category.title;
      }}
    />
  );
};
