import { PopConfirm, Tree } from "@getprorecrutement/getpro-design";
import { ChevronDownIcon, ChevronUpIcon, PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { Category, CategoryTreeElem } from "../models/Category";
import { Hierarchy } from "../models/Hierarchy";
import { JobTitleResponse } from "../models/JobTitle";
import { Uuid } from "../services/routes";

export interface CategoryTreeInterface {
  category: CategoryTreeElem;
  selectedParent?: CategoryTreeElem;
  forceOpen?: boolean;
  hierarchies?: { [key: Uuid]: Hierarchy };
  renderAction?: (item: Category) => JSX.Element;
  onEdit?: (elem: { category: Category; parent?: CategoryTreeElem }) => void;
  onJobTitleDelete?: (id: Uuid) => void;
  onJobTitleEdit?: (jobTitle: { title: JobTitleResponse; parent: CategoryTreeElem }) => void;
  onDelete?: (elem: CategoryTreeElem) => void;
  onClick?: (elem: CategoryTreeElem) => void;
  onJobTitleCreate?: (parent: Category) => void;
}

export interface JobTitleListProps {
  jobTitles: JobTitleResponse[];
  onEdit?: (title: JobTitleResponse) => void;
  onDelete?: (id: Uuid) => void;
  onCreate?: () => void;
  hierarchies?: { [key: Uuid]: Hierarchy };
}

export const JobTitleList: FunctionComponent<JobTitleListProps> = ({
  jobTitles,
  onEdit,
  onDelete,
  onCreate,
  hierarchies,
}) => {
  return (
    <div className="flex flex-wrap gap-5 p-5 bg-primary-dark rounded-xl">
      {jobTitles.map((elem) => {
        const classname = classNames(
          "rounded-lg bg-primary-lighter w-fit py-1 px-3 text-primary-darker group flex justify-center items-center gap-5 border-4",
          {
            "border-red-500": hierarchies?.[elem.hierarchy_id].title === "Dirigeant",
            "border-blue-500": hierarchies?.[elem.hierarchy_id].title === "Manager",
            "border-black": hierarchies?.[elem.hierarchy_id].title === "Contributeur individuel",
          }
        );

        return (
          <div className={classname}>
            <div>{elem.title}</div>
            <div className="flex justify-center items-center gap-2">
              {onEdit && <PencilIcon className="cursor-pointer" height={18} width={18} onClick={() => onEdit(elem)} />}
              {onDelete && (
                <PopConfirm
                  onValidate={() => onDelete(elem.id)}
                  title="Voulez vous vraiment supprimer le titre de poste ?"
                >
                  <TrashIcon className="cursor-pointer" height={18} width={18} />
                </PopConfirm>
              )}
            </div>
          </div>
        );
      })}
      {onCreate && <PlusCircleIcon className="cursor-pointer self-center" height={24} width={24} onClick={onCreate} />}
    </div>
  );
};

export const CategoryTree: FunctionComponent<CategoryTreeInterface> = ({
  category,
  selectedParent,
  renderAction,
  onEdit,
  onJobTitleEdit,
  onJobTitleDelete,
  onJobTitleCreate,
  onDelete,
  hierarchies,
  forceOpen = false,
  onClick,
}) => {
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    setOpened(forceOpen);
  }, [forceOpen]);

  const classes = classNames("rounded-xl px-2 group flex justify-center items-center gap-3", {
    "bg-blue-500": selectedParent?.category.id === category.category.id,
    "cursor-pointer hover:font-bold": !!onClick,
  });

  const renderLabel = (category: CategoryTreeElem, onEdit?: (category: CategoryTreeElem) => void) => {
    return (
      <div key={`label-${category.category.id}`} onClick={() => onClick?.(category)} className={classes}>
        {category.category.title}
        {onEdit && (
          <PencilIcon
            onClick={() => onEdit(category)}
            height={18}
            width={18}
            className="cursor-pointer hidden group-hover:block"
          />
        )}
        {onDelete && (
          <PopConfirm onValidate={() => onDelete(category)} title="Voulez vous vraiment supprimer la catégorie ?">
            <TrashIcon height={18} width={18} className="cursor-pointer hidden group-hover:block" />
          </PopConfirm>
        )}
      </div>
    );
  };

  const renderSubElem = (category: CategoryTreeElem) => (
    <div>
      <Tree
        key={category.category.id}
        values={category.childs}
        renderAction={(renderAction && (() => renderAction(category.category))) || undefined}
        getKey={(elem) => elem.category.id}
        renderSubElem={(elem) => {
          return renderSubElem(elem);
        }}
        render={(v) =>
          renderLabel(
            v,
            onEdit
              ? (c) => {
                  onEdit({ category: c.category, parent: category });
                }
              : undefined
          )
        }
      />
      {category.jobTitles && onJobTitleEdit && (
        <JobTitleList
          hierarchies={hierarchies}
          jobTitles={category.jobTitles}
          onDelete={onJobTitleDelete}
          onEdit={(title) => onJobTitleEdit({ title: title, parent: category })}
          onCreate={onJobTitleCreate && (() => onJobTitleCreate(category.category))}
        />
      )}
    </div>
  );

  return (
    <div>
      <div
        className="px-3 py-1 flex cursor-pointer justify-left items-center gap-1"
        onClick={() => {
          setOpened(!opened);
          onClick?.(category);
        }}
      >
        <div className={classes}>
          {category.category.title}
          {onEdit && (
            <PencilIcon
              onClick={() => onEdit({ category: category.category })}
              height={18}
              width={18}
              className="cursor-pointer hidden group-hover:block"
            />
          )}
          {onDelete && (
            <PopConfirm onValidate={() => onDelete(category)} title="Voulez vous vraiment supprimer la catégorie ?">
              <TrashIcon height={18} width={18} className="cursor-pointer hidden group-hover:block" />
            </PopConfirm>
          )}

          {opened ? (
            <ChevronUpIcon
              onClick={(ev) => {
                ev.stopPropagation();
                setOpened(!opened);
              }}
              height={24}
              width={24}
            />
          ) : (
            <ChevronDownIcon
              onClick={(ev) => {
                ev.stopPropagation();
                setOpened(!opened);
              }}
              height={24}
              width={24}
            />
          )}
        </div>
      </div>
      <div className="ml-2">
        {opened && (
          <div>
            <Tree
              values={category.childs}
              renderAction={(renderAction && (() => renderAction(category.category))) || undefined}
              getKey={(elem) => elem.category.id}
              renderSubElem={renderSubElem}
              render={(v) =>
                renderLabel(
                  v,
                  onEdit
                    ? (c) => {
                        onEdit({ category: c.category, parent: category });
                      }
                    : undefined
                )
              }
            />
            {category.jobTitles && onJobTitleEdit && (
              <JobTitleList
                hierarchies={hierarchies}
                jobTitles={category.jobTitles}
                onDelete={onJobTitleDelete}
                onEdit={onJobTitleEdit ? (title) => onJobTitleEdit({ title: title, parent: category }) : undefined}
                onCreate={onJobTitleCreate && (() => onJobTitleCreate(category.category))}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
