import { NewTestAttempt, TestAggregated, TestAttemptStat } from "./../models/testAttempt";
import { Hierarchy } from "./../models/Hierarchy";
import {
  JobTitleFilter,
  JobTitleResponse,
  NewJobTitle,
  UnvalidatedJobTitle,
  UnvalidatedJobTitleUpdate,
} from "./../models/JobTitle";
import { AuthResponse, LoginPayload } from "../models/auth";
import { AxiosRequestConfig } from "axios";
import { NewHierarchy } from "../models/Hierarchy";
import { Category, NewCategory } from "../models/Category";
import { ChangeRecord, ChangeRecordFilter } from "../models/changeRecord";
import { Paginated, PaginationParams } from "../models/pagination";
import { NewSkill, Skill, SkillFilters, SkillUpdate } from "../models/skill";

export enum Method {
  Get = "get",
  Post = "post",
  Patch = "patch",
  Delete = "delete",
}

export enum API {
  Haven,
  App,
}

export const URLS = {
  [API.Haven]: process.env.REACT_APP_HAVEN_API_URL,
  [API.App]: process.env.REACT_APP_API_URL,
};

export interface Route<T> {
  _phantomData?: T;
  api: API;
  path: string;
  method: Method;
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  auth?: boolean;
  file?: File;
  headers?: { Authorization?: string };
}

export type Uuid = string;

interface Routes {
  LOGIN: (data: LoginPayload) => Route<AuthResponse>;
  REFRESH_JWT: (token: string) => Route<AuthResponse>;
  CREATE_CATEGORY: (category: NewCategory) => Route<Category>;
  DELETE_CATEGORY: (id: Uuid) => Route<{}>;
  UPDATE_CATEGORY: (category: Category) => Route<Category>;
  FETCH_CATEGORIES: () => Route<Category[]>;
  CREATE_JOB_TITLE: (jobTitle: NewJobTitle) => Route<JobTitleResponse>;
  DELETE_JOB_TITLE: (id: Uuid) => Route<{}>;
  UPDATE_JOB_TITLE: (jobTitle: JobTitleResponse) => Route<JobTitleResponse>;
  FETCH_JOB_TITLES: (filter?: JobTitleFilter) => Route<JobTitleResponse[]>;
  CREATE_HIERARCHY: (hierarchy: NewHierarchy) => Route<Hierarchy>;
  DELETE_HIERARCHY: (id: Uuid) => Route<{}>;
  GET_STATS: () => Route<TestAttemptStat[]>;
  UPDATE_HIERARCHY: (hierarchy: Hierarchy) => Route<Hierarchy>;
  CREATE_TEST_ATTEMPT: (test: NewTestAttempt) => Route<{}>;
  FETCH_HIERARCHIES: () => Route<Hierarchy[]>;
  GET_CATEGORY_PATH: (id: Uuid) => Route<Category[]>;
  MIGRATE_CATEGORY: (from_id: Uuid, to_id: Uuid) => Route<{}>;
  GET_STATS_FOR_JOB_TITLE: (jobTitleId: Uuid) => Route<TestAggregated[]>;
  FETCH_UNVALIDATED_JOB_TITLES: () => Route<UnvalidatedJobTitle[]>;
  FETCH_RANDOM_UNVALIDATED_JOB_TITLE: () => Route<UnvalidatedJobTitle>;
  UPDATE_UNVALIDATED_JOB_TITLES: (id: Uuid, payload: UnvalidatedJobTitleUpdate) => Route<UnvalidatedJobTitle>;
  ASSIGN_TO_JOB_TITLE: (fromId: Uuid, toId: Uuid, keepAsSynonym: boolean) => Route<JobTitleResponse>;
  VALIDATE_UNVALIDATED_JOB_TITLES: (id: Uuid) => Route<JobTitleResponse>;
  DELETE_UNVALIDATED_JOB_TITLES: (id: Uuid) => Route<JobTitleResponse>;
  ASSIGN_UNVALIDATED_TO_JOB_TITLE: (id: Uuid, toId: Uuid, keepAsSynonym: boolean) => Route<JobTitleResponse>;
  GET_SIMILAR_JOB_TITLES: (id: Uuid) => Route<JobTitleResponse[]>;
  GET_CHANGE_RECORDS: (filters?: ChangeRecordFilter & PaginationParams) => Route<Paginated<ChangeRecord>>;
  DELETE_CHANGE_RECORDS: (id: Uuid) => Route<{}>;
  REVERT_CHANGE_RECORDS: (id: Uuid) => Route<{}>;
  FETCH_SKILLS: (filters?: SkillFilters) => Route<Skill[]>;
  CREATE_SKILL: (payload: NewSkill) => Route<Skill>;
  UPDATE_SKILL: (id: Uuid, payload: SkillUpdate) => Route<Skill>;
  DELETE_SKILL: (id: Uuid) => Route<{}>;
}

const ROUTES: Routes = {
  CREATE_CATEGORY: (hierarchy: NewCategory) => ({
    api: API.App,
    path: "/categories",
    method: Method.Post,
    auth: true,
    data: hierarchy,
  }),
  MIGRATE_CATEGORY: (from_id: Uuid, to_id: Uuid) => ({
    api: API.App,
    path: `/categories/${from_id}/migrate_to/${to_id}`,
    method: Method.Patch,
    auth: true,
  }),
  GET_STATS_FOR_JOB_TITLE: (jobTitleId: Uuid) => ({
    api: API.App,
    path: `/test_attempts/${jobTitleId}/stats`,
    method: Method.Get,
    auth: true,
  }),
  GET_STATS: () => ({
    api: API.App,
    path: "/test_attempts/stats",
    auth: true,
    method: Method.Get,
  }),
  GET_CATEGORY_PATH: (id: Uuid) => ({
    api: API.App,
    path: `/categories/${id}/path`,
    method: Method.Get,
    auth: true,
  }),
  GET_CHANGE_RECORDS: (filters?: ChangeRecordFilter) => ({
    api: API.App,
    path: `/change_records/`,
    method: Method.Get,
    params: filters,
    auth: true,
  }),
  DELETE_CHANGE_RECORDS: (id: Uuid) => ({
    api: API.App,
    path: `/change_records/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  REVERT_CHANGE_RECORDS: (id: Uuid) => ({
    api: API.App,
    path: `/change_records/${id}/revert`,
    method: Method.Delete,
    auth: true,
  }),

  DELETE_CATEGORY: (id: Uuid) => ({
    api: API.App,
    path: `/categories/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_CATEGORY: (category: Category) => ({
    api: API.App,
    path: `/categories/${category.id}`,
    method: Method.Patch,
    auth: true,
    data: category,
  }),
  CREATE_TEST_ATTEMPT: (test: NewTestAttempt) => ({
    api: API.App,
    path: "/test_attempts",
    method: Method.Post,
    auth: true,
    data: test,
  }),
  FETCH_CATEGORIES: () => ({
    api: API.App,
    path: "/categories",
    method: Method.Get,
    auth: true,
  }),
  CREATE_HIERARCHY: (hierarchy: NewHierarchy) => ({
    api: API.App,
    path: "/hierarchies",
    method: Method.Post,
    auth: true,
    data: hierarchy,
  }),
  DELETE_HIERARCHY: (id: Uuid) => ({
    api: API.App,
    path: `/hierarchies/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_HIERARCHY: (hierarchy: Hierarchy) => ({
    api: API.App,
    path: `/job_titles/${hierarchy.id}`,
    method: Method.Patch,
    auth: true,
    data: hierarchy,
  }),
  FETCH_HIERARCHIES: () => ({
    api: API.App,
    path: "/hierarchies",
    method: Method.Get,
    auth: true,
  }),
  CREATE_JOB_TITLE: (jobTitle: NewJobTitle) => ({
    api: API.App,
    path: "/job_titles",
    method: Method.Post,
    auth: true,
    data: jobTitle,
  }),
  DELETE_JOB_TITLE: (id: Uuid) => ({
    api: API.App,
    path: `/job_titles/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  UPDATE_JOB_TITLE: (jobTitle: JobTitleResponse) => ({
    api: API.App,
    path: `/job_titles/${jobTitle.id}`,
    method: Method.Patch,
    auth: true,
    data: jobTitle,
  }),
  FETCH_JOB_TITLES: (payload?: JobTitleFilter) => ({
    api: API.App,
    path: "/job_titles",
    method: Method.Get,
    params: payload,
    auth: true,
  }),
  FETCH_UNVALIDATED_JOB_TITLES: () => ({
    api: API.App,
    path: "/job_titles/unvalidated",
    method: Method.Get,
    auth: true,
  }),
  FETCH_RANDOM_UNVALIDATED_JOB_TITLE: () => ({
    api: API.App,
    path: "/job_titles/unvalidated/random",
    method: Method.Get,
    auth: true,
  }),
  GET_SIMILAR_JOB_TITLES: (id) => ({
    api: API.App,
    path: `/job_titles/unvalidated/${id}/similar`,
    method: Method.Get,
    auth: true,
  }),
  UPDATE_UNVALIDATED_JOB_TITLES: (id: Uuid, payload: UnvalidatedJobTitleUpdate) => ({
    api: API.App,
    path: `/job_titles/unvalidated/${id}`,
    method: Method.Patch,
    data: payload,
    auth: true,
  }),
  ASSIGN_TO_JOB_TITLE: (id: Uuid, toId: Uuid, keepAsSynonym: boolean) => ({
    api: API.App,
    path: `/job_titles/${id}/assign_to/${toId}`,
    params: {
      create_synonym: keepAsSynonym,
    },
    method: Method.Delete,
    auth: true,
  }),
  ASSIGN_UNVALIDATED_TO_JOB_TITLE: (id: Uuid, toId: Uuid, keepAsSynonym: boolean) => ({
    api: API.App,
    path: `/job_titles/unvalidated/${id}/assign_to/${toId}`,
    method: Method.Delete,
    params: {
      create_synonym: keepAsSynonym,
    },
    auth: true,
  }),
  FETCH_SKILLS: (filters?: SkillFilters) => ({
    api: API.App,
    path: `/skills`,
    method: Method.Get,
    params: filters,
    auth: true,
  }),
  CREATE_SKILL: (payload: NewSkill) => ({
    api: API.App,
    path: `/skills`,
    method: Method.Post,
    data: payload,
    auth: true,
  }),
  UPDATE_SKILL: (id: Uuid, payload: SkillUpdate) => ({
    api: API.App,
    path: `/skills/${id}/`,
    method: Method.Patch,
    data: payload,
    auth: true,
  }),
  DELETE_SKILL: (id: Uuid) => ({
    api: API.App,
    path: `/skills/${id}/`,
    method: Method.Delete,
    auth: true,
  }),
  VALIDATE_UNVALIDATED_JOB_TITLES: (id: Uuid) => ({
    api: API.App,
    path: `/job_titles/unvalidated/${id}/validate`,
    method: Method.Post,
    auth: true,
  }),
  DELETE_UNVALIDATED_JOB_TITLES: (id: Uuid) => ({
    api: API.App,
    path: `/job_titles/unvalidated/${id}`,
    method: Method.Delete,
    auth: true,
  }),
  LOGIN: (data) => ({
    api: API.Haven,
    path: "/users/login/",
    method: Method.Post,
    auth: false,
    data,
  }),
  REFRESH_JWT: (token) => ({
    api: API.Haven,
    path: `/users/refresh/${token}/`,
    method: Method.Get,
    auth: false,
  }),
};

export default ROUTES;
