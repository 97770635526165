import { toast } from "react-hot-toast";
import httpClient from "../services/httpClient";
import ROUTES, { Uuid } from "../services/routes";

export interface JobTitleResponse {
  id: string;
  category_id: string;
  hierarchy_id: string;
  title: string;
  test_count: number;
}

export interface JobTitle {
  id: string;
  category_id: string;
  hierarchy_id: string;
  title: string;
}

export interface JobTitleUpdate {
  title?: string;
  category_id?: Uuid;
  hierarchy_id?: Uuid;
}

export interface UnvalidatedJobTitle {
  id: string;
  category_id: string;
  hierarchy_id: string;
  title: string;
}

export interface NewJobTitle {
  category_id: string;
  hierarchy_id: string;
  title: string;
}

export interface UnvalidatedJobTitleUpdate {
  category_id?: string;
  hierarchy_id?: string;
  title?: string;
}

export interface JobTitleFilter {
  search?: string;
  category_id?: Uuid;
}

export const createJobTitle = (jobTitle: NewJobTitle) =>
  httpClient.req(ROUTES.CREATE_JOB_TITLE(jobTitle)).then((res) => {
    toast.success("Le titre à été créé");
    return res;
  });
export const deleteJobTitle = (id: Uuid) =>
  httpClient.req(ROUTES.DELETE_JOB_TITLE(id)).then((res) => {
    toast.success("Le titre à été supprimé");
    return res;
  });
export const updateJobTitle = (jobTitle: JobTitleResponse) =>
  httpClient.req(ROUTES.UPDATE_JOB_TITLE(jobTitle)).then((res) => {
    toast.success("Le titre à été mis à jour");
    return res;
  });
export const fetchJobTitles = (payload?: JobTitleFilter) => httpClient.req(ROUTES.FETCH_JOB_TITLES(payload));

export const assignToJobTitle = (id: Uuid, toId: Uuid, keepAsSynonym: boolean) =>
  httpClient.req(ROUTES.ASSIGN_TO_JOB_TITLE(id, toId, keepAsSynonym));

// unvalidated
export const fetchUnvalidatedJobTitles = () => httpClient.req(ROUTES.FETCH_UNVALIDATED_JOB_TITLES());
export const fetchRandomUnvalidatedJobTitle = () => httpClient.req(ROUTES.FETCH_RANDOM_UNVALIDATED_JOB_TITLE());
export const getSimilarJobTitles = (id: Uuid) => httpClient.req(ROUTES.GET_SIMILAR_JOB_TITLES(id));
export const validateUnvalidateJobTitle = (id: Uuid) =>
  httpClient.req(ROUTES.VALIDATE_UNVALIDATED_JOB_TITLES(id)).then((res) => {
    toast.success("Le titre à été validé");
    return res;
  });
export const deleteUnvalidateJobTitle = (id: Uuid) =>
  httpClient.req(ROUTES.DELETE_UNVALIDATED_JOB_TITLES(id)).then((res) => {
    toast.success("Le titre à été supprimé");
    return res;
  });
export const assignUnvalidatedToJobTitle = (id: Uuid, toId: Uuid, keepAsSynonym: boolean) =>
  httpClient.req(ROUTES.ASSIGN_UNVALIDATED_TO_JOB_TITLE(id, toId, keepAsSynonym)).then((res) => {
    toast.success("Le titre à été assigné");
    return res;
  });
export const updateUnvalidatedJobTitle = (id: Uuid, payload: UnvalidatedJobTitleUpdate) =>
  httpClient.req(ROUTES.UPDATE_UNVALIDATED_JOB_TITLES(id, payload)).then((res) => {
    toast.success("Le titre à été mis à jour");
    return res;
  });
