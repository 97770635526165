import { Button, ColorType, Modal } from "@getprorecrutement/getpro-design";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddingCategoryForm } from "../components/AddingCategoryForm";
import { CategorySearch } from "../components/CategorySearch";
import { CategoryTree } from "../components/CategoryTree";
import { Header } from "../components/Header";
import { JobTitleForm } from "../components/JobTitleForm";
import { UpdateCategoryForm } from "../components/UpdateCategoryForm";
import { UpdateJobTitleForm } from "../components/UpdateJobTitleForm";
import {
  Category,
  CategoryTreeElem,
  constructCategoryTree,
  createCategory,
  fetchCategories,
  migrateCategory,
  NewCategory,
  updateCategory,
} from "../models/Category";
import { fetchHierarchies, Hierarchy } from "../models/Hierarchy";
import {
  assignToJobTitle,
  createJobTitle,
  deleteJobTitle,
  fetchJobTitles,
  JobTitle,
  JobTitleResponse,
  updateJobTitle,
} from "../models/JobTitle";
import { Uuid } from "../services/routes";

const JobTreePage: FunctionComponent = (): JSX.Element => {
  const [categories, setCategories] = useState<CategoryTreeElem[]>();
  const [editingCategory, setEditingCategory] = useState<{ category: Category; parent?: CategoryTreeElem }>();
  const [deletingCategory, setDeletingCategory] = useState<CategoryTreeElem>();
  const [addingCategory, setAddingCategory] = useState<Category>();
  const [editingJobTitle, setEditingJobTitle] = useState<{ parent: CategoryTreeElem; title: JobTitleResponse }>();
  const [jobTitles, setJobTitles] = useState<JobTitle[]>();
  const [hierarchies, setHierarchies] = useState<Hierarchy[]>();
  const [newJobTitleCategory, setNewJobTitleCategory] = useState<Category>();
  const navigate = useNavigate();

  const fetchDatas = useCallback(async () => {
    const categories = await fetchCategories();
    const jobTitles = await fetchJobTitles();
    const hierarchies = await fetchHierarchies();
    setJobTitles(jobTitles);
    setHierarchies(hierarchies);
    setCategories(constructCategoryTree(categories, jobTitles));
  }, []);

  useEffect(() => {
    fetchDatas();
  }, [fetchDatas]);

  const onJobTitleCreate = async (payload: { title: string; hierarchy_id: Uuid }) => {
    if (!newJobTitleCategory) return;
    await createJobTitle({
      category_id: newJobTitleCategory.id,
      ...payload,
    });
    setNewJobTitleCategory(undefined);
    fetchDatas();
  };

  const onCategoryEdit = async (category: Category) => {
    if (!editingCategory) return;
    await updateCategory(category);
    setEditingCategory(undefined);
    fetchDatas();
  };

  const onJobTitleEdit = async (jobTitle: JobTitleResponse) => {
    if (!editingJobTitle) return;
    await updateJobTitle(jobTitle);
    setEditingJobTitle(undefined);
    fetchDatas();
  };

  const onMigrateCategory = async (category: Category) => {
    if (!deletingCategory) return;
    await migrateCategory(deletingCategory.category.id, category.id);
    setDeletingCategory(undefined);
    fetchDatas();
  };

  const onCreateCategory = async (newCategory: NewCategory) => {
    await createCategory(newCategory);
    setAddingCategory(undefined);
    fetchDatas();
  };

  const onJobTitleDelete = async (id: Uuid) => {
    await deleteJobTitle(id);
    fetchDatas();
  };

  const onAssign = async (id: Uuid, keepAsSynonym: boolean) => {
    if (!editingJobTitle) return;
    await assignToJobTitle(editingJobTitle?.title.id, id, keepAsSynonym);
    setEditingJobTitle(undefined);
    fetchDatas();
  };

  return (
    <div className="min-h-full w-full flex-grow bg-background-dark">
      <Header
        center={<div className="text-white font-bold text-3xl">Titres de postes</div>}
        left={
          <Button
            title="Titres non validés"
            size="small"
            colorType={ColorType.Content}
            onClick={() => navigate("/validation")}
            dark
            kind="outline"
          />
        }
      />
      <div className="text-white h-full text-lg max-w-6xl w-full p-10 mt-20 rounded-3xl bg-primary-medium mx-auto">
        <div>
          {categories?.map((c) => (
            <CategoryTree
              hierarchies={
                hierarchies?.reduce((acc, next) => {
                  acc[next.id] = next;
                  return acc;
                }, {} as { [key: Uuid]: Hierarchy }) || {}
              }
              onEdit={setEditingCategory}
              onDelete={setDeletingCategory}
              onJobTitleEdit={setEditingJobTitle}
              onJobTitleDelete={onJobTitleDelete}
              onJobTitleCreate={setNewJobTitleCategory}
              category={c}
              renderAction={(category) => {
                return (
                  <PlusCircleIcon
                    height={20}
                    width={20}
                    className="cursor-pointer"
                    onClick={() => setAddingCategory(category)}
                  />
                );
              }}
            />
          ))}
        </div>
      </div>
      <Modal show={!!editingCategory} onClose={() => setEditingCategory(undefined)}>
        {editingCategory && (
          <UpdateCategoryForm categories={categories || []} category={editingCategory} onFinish={onCategoryEdit} />
        )}
      </Modal>
      <Modal show={!!deletingCategory} onClose={() => setDeletingCategory(undefined)}>
        <div>
          <div className="w-full max-w-xs">
            Sélectionez une catégorie à laquelle réassigner les enfants de la catégorie{" "}
          </div>
          <CategorySearch
            title={deletingCategory?.category.title}
            filteredCategories={categories}
            categories={[]}
            onSelect={onMigrateCategory}
          />
        </div>
      </Modal>
      <Modal show={!!addingCategory} onClose={() => setAddingCategory(undefined)}>
        {addingCategory && <AddingCategoryForm fromCategory={addingCategory} onFinish={onCreateCategory} />}
      </Modal>
      <Modal show={!!editingJobTitle} onClose={() => setEditingJobTitle(undefined)}>
        {editingJobTitle && (
          <UpdateJobTitleForm
            onAssign={onAssign}
            categories={categories || []}
            hierarchies={hierarchies || []}
            jobTitles={jobTitles?.filter((j) => j.id !== editingJobTitle.title.id) || []}
            jobTitle={editingJobTitle}
            onFinish={onJobTitleEdit}
          />
        )}
      </Modal>
      {/* <Modal show={!!currentCategory} onClose={() => setCurrentCategory(undefined)}>
        {currentCategory && <SkillModal category={currentCategory} />}
      </Modal> */}
      <Modal show={!!newJobTitleCategory} onClose={() => setNewJobTitleCategory(undefined)}>
        <JobTitleForm hierarchies={hierarchies || []} onConfirm={onJobTitleCreate} />
      </Modal>
    </div>
  );
};

export default JobTreePage;
