import { Button, ColorType, Modal, MoreActions, PopConfirm, Tooltip } from "@getprorecrutement/getpro-design";
import { PencilIcon, SearchIcon, TrashIcon } from "@heroicons/react/outline";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CategorySearch } from "../components/CategorySearch";
import { EditTitleForm } from "../components/EditTitle";
import { Header } from "../components/Header";
import { JobTitleSearch } from "../components/JobTitleSearch";
import { Category, fetchCategories, getCategoryPath } from "../models/Category";
import { fetchHierarchies, Hierarchy } from "../models/Hierarchy";
import {
  assignUnvalidatedToJobTitle,
  deleteUnvalidateJobTitle,
  fetchJobTitles,
  fetchRandomUnvalidatedJobTitle,
  getSimilarJobTitles,
  JobTitle,
  JobTitleResponse,
  UnvalidatedJobTitle,
  updateUnvalidatedJobTitle,
  validateUnvalidateJobTitle,
} from "../models/JobTitle";

export const ValidationPage: FunctionComponent = () => {
  const [currentJobTitle, setCurrentJobTitle] = useState<UnvalidatedJobTitle>();
  const [hierarchies, setHierarchies] = useState<Hierarchy[]>();
  const [categoryPath, setCategoryPath] = useState<Category[]>();
  const [categories, setCategories] = useState<Category[]>();
  const [similarJobTitles, setSimilarJobTitles] = useState<{ title: JobTitleResponse; path: Category[] }[]>();
  const [neighborsJobTitles, setNeighborsJobTitles] = useState<JobTitleResponse[]>();
  //TODO should only fetch searched titles

  const [jobTitles, setJobTitles] = useState<JobTitleResponse[]>();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [showTitleEdition, setShowTitleEdition] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchHierarchies().then(setHierarchies);
    fetchJobTitles().then(setJobTitles);
    fetchCategories().then(setCategories);
    fetchRandomUnvalidatedJobTitle().then(setCurrentJobTitle);
  }, []);

  useEffect(() => {
    if (currentJobTitle) {
      fetchJobTitles({
        category_id: currentJobTitle.category_id,
      }).then(setNeighborsJobTitles);
      getCategoryPath(currentJobTitle.category_id).then((e) => setCategoryPath(e.reverse()));
      getSimilarJobTitles(currentJobTitle.id).then(async (t) => {
        const title = await Promise.all(
          t.map(async (t) => ({ title: t, path: (await getCategoryPath(t.category_id)).reverse() }))
        );
        return setSimilarJobTitles(title);
      });
    }
  }, [currentJobTitle]);

  const onHierarchyUpdate = async (hierarchy: Hierarchy) => {
    if (!currentJobTitle) return;
    const title = await updateUnvalidatedJobTitle(currentJobTitle.id, { hierarchy_id: hierarchy.id });
    setCurrentJobTitle(title);
  };

  const onUpdateCategory = async (category: Category) => {
    if (!currentJobTitle) return;
    const title = await updateUnvalidatedJobTitle(currentJobTitle.id, { category_id: category.id });
    setCurrentJobTitle(title);
    setShowCategories(false);
  };

  const onTitleEdit = async (new_title: string) => {
    if (!currentJobTitle) return;
    const title = await updateUnvalidatedJobTitle(currentJobTitle.id, { title: new_title });
    setCurrentJobTitle(title);
    setShowTitleEdition(false);
  };

  const next = async () => {
    const jobTitle = await fetchRandomUnvalidatedJobTitle();
    setCurrentJobTitle(jobTitle);
  };

  const onAssign = async (jobTitle: JobTitle, keepAsSynonym?: boolean) => {
    if (!currentJobTitle) return;

    await assignUnvalidatedToJobTitle(currentJobTitle.id, jobTitle.id, !!keepAsSynonym);
    await next();
    setShowSearch(false);
  };

  const onValidate = async () => {
    if (!currentJobTitle) return;
    await validateUnvalidateJobTitle(currentJobTitle.id);
    await next();
  };

  const onTitleDelete = async () => {
    if (!currentJobTitle) return;
    await deleteUnvalidateJobTitle(currentJobTitle.id);
    await next();
  };

  return (
    <div className=" bg-[#202020] h-screen">
      <Header
        center={<div className="text-white font-bold text-3xl">Validation</div>}
        left={
          <Button
            title="Retourner aux titres de postes"
            size="small"
            onClick={() => navigate("/")}
            colorType={ColorType.Content}
            dark
            kind="outline"
          />
        }
      />

      <div className="p-16 flex flex-col justify-center items-center text-white">
        {!currentJobTitle && <div className="text-xl">Aucun titre a valider</div>}
        {currentJobTitle && (
          <div className="mt-10 rounded-3xl p-10  max-w-6xl bg-[#323232]">
            <div className="font-bold text-primary-bright text-xl text-center group flex gap-5 items-center justify-start w-fit m-auto">
              <div className="ml-12 text-2xl">{currentJobTitle.title}</div>
              <PencilIcon
                height={24}
                width={24}
                className="invisible group-hover:visible cursor-pointer"
                onClick={() => setShowTitleEdition(true)}
              />
            </div>
            <div className="flex justify-center items-center gap-5 mt-10">
              <div className=" cursor-pointer">
                <MoreActions
                  dark
                  actions={hierarchies?.map((h) => ({ title: h.title, action: () => onHierarchyUpdate(h) })) || []}
                >
                  <div className="text-[#838383] font-bold">
                    {hierarchies?.find((h) => h.id === currentJobTitle.hierarchy_id)?.title}
                  </div>
                </MoreActions>
              </div>
              <div>{categoryPath?.map((category) => category.title).join("  ➞  ")}</div>
              <Button
                title="Recatégoriser"
                size="small"
                colorType={ColorType.Content}
                onClick={() => setShowCategories(true)}
              />
            </div>
            <div>
              <div className="ml-5 mt-5">Titres de la catégorie {categoryPath?.[categoryPath?.length - 1].title}</div>
              <div className="p-5 mt-5 flex justify-left gap-5 flex-wrap rounded-xl bg-primary-dark">
                {neighborsJobTitles?.map((title) => (
                  <PopConfirm
                    title="Créer un synonyme (cliquer sur non assignera sans créer de synonyme)"
                    onValidate={() => onAssign(title, true)}
                    onRefuse={() => onAssign(title, false)}
                    position="bottom"
                  >
                    <Button title={title.title} className="w-max" size="small" colorType={ColorType.Content} dark />
                  </PopConfirm>
                ))}
              </div>
            </div>
            <div className="flex justify-center items-center border mt-5 rounded-lg">
              <div className="font-bold self-center m-10">
                <div>Titres similaires:</div>
                <Button
                  size="small"
                  title="Rechercher"
                  onClick={() => setShowSearch(true)}
                  dark
                  icon={<SearchIcon />}
                  kind="light"
                  colorType={ColorType.Content}
                />
              </div>
              <div className="p-5  flex justify-left gap-5 flex-wrap ">
                {similarJobTitles?.map(({ title, path }) => (
                  <PopConfirm
                    title="Créer un synonyme (cliquer sur non assignera sans créer de synonyme)"
                    onValidate={() => onAssign(title, true)}
                    onRefuse={() => onAssign(title, false)}
                    position="bottom"
                  >
                    <Tooltip title={path.map((category) => category.title).join("  ➞  ")}>
                      <Button
                        title={title.title}
                        className="w-max"
                        // onClick={() => onAssign(title)}
                        size="small"
                        colorType={ColorType.Content}
                        dark
                      />
                    </Tooltip>
                  </PopConfirm>
                ))}
              </div>
            </div>

            <div className="mt-10 flex justify-between gap-5">
              <PopConfirm title="Êtes vous sur de vouloir supprimer ce titre ?" onValidate={onTitleDelete}>
                <Button title="Supprimer le titre" colorType={ColorType.Content} dark icon={<TrashIcon />} />
              </PopConfirm>
              <div className="flex items-center gap-5">
                <Button
                  title="Passer le titre"
                  kind="outline"
                  colorType={ColorType.Content}
                  dark
                  onClick={() => next()}
                />
                <Button title="Valider le titre" colorType={ColorType.Content} onClick={onValidate} />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal className="bg-white" show={showCategories} onClose={() => setShowCategories(false)}>
        <CategorySearch categories={categories || []} onSelect={(c) => onUpdateCategory(c)} />
      </Modal>
      <Modal className="bg-white" show={showSearch} onClose={() => setShowSearch(false)}>
        <JobTitleSearch jobTitles={jobTitles || []} onConfirm={onAssign} />
      </Modal>
      <Modal show={showTitleEdition} onClose={() => setShowTitleEdition(false)}>
        <EditTitleForm text={currentJobTitle?.title} onFinished={onTitleEdit} />
      </Modal>
    </div>
  );
};
