import { JWT, AuthResponse } from "../models/auth";
import JwtDecode from "jwt-decode";

type Listener<T extends Message | Stored> = (value?: StoreTypes[T]) => void;

function serverTempLocalStorage() {
  let storage: any = {};

  return {
    clear: () => {
      storage = {};
    },
    setItem: function (k: any, v: any) {
      storage[k] = v || "";
    },
    getItem: function (k: any) {
      return k in storage ? storage[k] : null;
    },
    removeItem: function (k: any) {
      delete storage[k];
    },
    get length() {
      return Object.keys(storage).length;
    },
    key: function (i: any) {
      const keys = Object.keys(storage);
      return keys[i] || null;
    },
  };
}
let storage: Storage;

if (typeof window === "undefined") storage = serverTempLocalStorage();
else storage = localStorage;

const log = (a: unknown, ...s: unknown[]): void => {
  if (process.env.NODE_ENV === "development") console.log(a, s);
};

// Please stringify every element
export enum Message {
  NeedAuth = "NeedAuth",
  Notification = "Notification",
  Error = "Error",
}

export enum Stored {
  JWT = "JWT",
  RawJWT = "RawJWT",
  RefreshToken = "RefreshToken",
}

interface StoreTypes {
  readonly [Message.NeedAuth]: string;
  readonly [Message.Notification]: string;
  readonly [Message.Error]: string;
  readonly [Stored.JWT]: JWT;
  readonly [Stored.RawJWT]: string;
  readonly [Stored.RefreshToken]: string;
}

const DEFAULT_PERSISTENT_KEYS: Stored[] = [Stored.RefreshToken];

class Store {
  private persistentKeys: Stored[];
  private listeners: {
    [key in Stored | Message]?: Array<Listener<Message | Stored>>;
  };
  public state: { [key in Stored]?: StoreTypes[key] };

  constructor() {
    this.listeners = {};
    this.persistentKeys = DEFAULT_PERSISTENT_KEYS;
    this.state = this.readState() || {}; // Order dependent
  }

  public listen<T extends Message | Stored>(type: T, callback: Listener<T>): () => void {
    this.getListeners(type).push(callback);
    log("[Store -> listen -> useEffect]", type);

    return function cleanup(): void {
      log("[Store -> forget -> useEffect]", type);
      store.forget(type, callback);
    };
  }

  public forget<T extends Message | Stored>(type: T, callback: Listener<T>): void {
    this.listeners[type] = this.getListeners<Message | Stored>(type).filter((cb) => cb !== callback);
  }

  public update<K extends Stored, V extends StoreTypes[K] | undefined>(key: K, value: V): V {
    log("[Store -> update]", key, value);
    if (value === undefined || value === null) {
      const { [key]: _omitted, ...stored } = this.state;
      this.state = stored;
    } else {
      this.state[key] = value;
    }
    if (this.persistentKeys.includes(key)) this.saveState();
    this.getListeners(key).forEach((listener: Listener<K>) => listener(value));
    return value;
  }

  public notify<M extends Message>(key: M, data?: StoreTypes[M]): void {
    log("[Store -> notify]", key, data);
    this.getListeners(key).forEach((listener: Listener<M>) => listener(data));
  }

  private getListeners<T extends Message | Stored>(type: T): Listener<T>[] {
    this.listeners[type] = this.listeners[type] || [];
    return this.listeners[type] as Listener<T>[];
  }

  public setCredentials(data: AuthResponse): void {
    this.update(Stored.JWT, JwtDecode(data.jwt) as JWT);
    this.update(Stored.RawJWT, data.jwt);
    this.update(Stored.RefreshToken, data.token);
  }

  public setPersistable(key: Stored, persist: boolean): void {
    if (persist) this.persistentKeys.push(key);
    else {
      const index = this.persistentKeys.indexOf(key);
      if (index > -1) {
        this.persistentKeys.splice(index, 1);
      }
    }
  }

  private readState(): StoreTypes | null {
    try {
      const state = JSON.parse(storage.getItem("state") || "{}") || {};
      if (state[Stored.RefreshToken]) this.setPersistable(Stored.RefreshToken, true); // If user already wanted to stay logged in, do it again
      return state;
    } catch (err) {
      console.warn("Cannot read the localStorage state");
      console.error(err);
      return null;
    }
  }

  private saveState(): void {
    try {
      const filtered = (Object.keys(this.state) as Stored[])
        .filter((key) => this.persistentKeys.includes(key))
        .reduce((obj: { [key in Stored]?: StoreTypes[key] }, key) => {
          return { ...obj, [key]: this.state[key] };
        }, {});
      storage.setItem("state", JSON.stringify(filtered));
    } catch (err) {
      console.warn("Cannot read the localStorage state");
    }
  }
}

const store = new Store();

export default store;
