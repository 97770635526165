import { Pagination } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useEffect, useState } from "react";
import { Header } from "../components/Header";
import { RecordRow } from "../components/RecordRow";
import { Category, fetchCategories } from "../models/Category";
import {
  ChangeRecord,
  ChangeRecordFilter,
  deleteChangeRecord,
  getChangeRecords,
  revertChangeRecord,
} from "../models/changeRecord";
import { fetchHierarchies, Hierarchy } from "../models/Hierarchy";
import { Paginated, PaginationParams } from "../models/pagination";
import { Uuid } from "../services/routes";

export const ChangeRecordsPage: FunctionComponent = () => {
  const [records, setRecords] = useState<Paginated<ChangeRecord>>();
  const [filters, setFilters] = useState<ChangeRecordFilter & PaginationParams>({
    page: 1,
  });
  const [categories, setCategories] = useState<{ [key: Uuid]: Category }>();
  const [hierarchies, setHierarchies] = useState<{ [key: Uuid]: Hierarchy }>();

  useEffect(() => {
    fetchCategories().then((c) =>
      setCategories(
        c.reduce((acc, next) => {
          acc[next.id] = next;
          return acc;
        }, {} as { [key: Uuid]: Category })
      )
    );
    fetchHierarchies().then((h) =>
      setHierarchies(
        h.reduce((acc, next) => {
          acc[next.id] = next;
          return acc;
        }, {} as { [key: Uuid]: Hierarchy })
      )
    );
  }, []);

  useEffect(() => {
    getChangeRecords(filters).then(setRecords);
  }, [filters]);

  const onChangeRecordRevert = async (id: Uuid) => {
    await revertChangeRecord(id);
    const records = await getChangeRecords(filters);
    setRecords(records);
  };

  const onChangeRecordDelete = async (id: Uuid) => {
    await deleteChangeRecord(id);
    const records = await getChangeRecords(filters);
    setRecords(records);
  };

  return (
    <div className="min-h-full w-full flex-grow bg-background-dark">
      <Header center={<div className="text-white font-bold text-3xl">Historique des changements</div>} />
      <div className="flex justify-center items-center pt-20 w-full">
        <div className="max-w-5xl w-full">
          {categories &&
            hierarchies &&
            records?.data?.map((elem) => (
              <RecordRow
                key={elem.id}
                categories={categories}
                hierarchies={hierarchies}
                record={elem}
                onDelete={onChangeRecordDelete}
                onRevert={onChangeRecordRevert}
              />
            ))}
          <div className="py-5 flex justify-end">
            <Pagination
              dark
              page={filters?.page || 1}
              total={records?.total || 0}
              onChange={(page) => {
                setFilters({ ...filters, page: page });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
