import httpClient from "../services/httpClient";
import ROUTES, { Uuid } from "./../services/routes";

export interface NewTestAttempt {
  user_id: Uuid;
  valid: boolean;
  job_title_id: Uuid;
  sent_category_path?: String;
}

export interface TestAttemptStat {
  sucess_percentage: number;
  job_title_id: Uuid;
  job_title_name: String;
}

export interface TestAggregated {
  job_title_id: Uuid;
  count: number;
  sent_category_path: String;
}

export const createTestAttempt = (test: NewTestAttempt) => httpClient.req(ROUTES.CREATE_TEST_ATTEMPT(test));
export const getStats = () => httpClient.req(ROUTES.GET_STATS());
export const getStatForJobTitle = (job_title_id: Uuid) => httpClient.req(ROUTES.GET_STATS_FOR_JOB_TITLE(job_title_id));
