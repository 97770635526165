import { FunctionComponent, useEffect, useState } from "react";
import { NewSkill, Skill, SkillUpdate, createSkill, deleteSkill, fetchSkills, updateSkill } from "../models/skill";
import { Header } from "../components/Header";
import { CheckIcon, PencilIcon, PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import { Button, Modal, PopConfirm, TextInput } from "@getprorecrutement/getpro-design";
import { toast } from "react-hot-toast";
import { Uuid } from "../services/routes";
import { SkillForm } from "../components/SkillForm";

export interface SkillElemProps {
  skill: Skill;
  onValidate: () => void;
  onDelete: () => void;
  onEdit: (s: SkillUpdate) => void;
}

export const SkillElem: FunctionComponent<SkillElemProps> = ({ skill, onValidate, onDelete, ...props }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [input, setInput] = useState<string>(skill.name);

  const onEdit = () => {
    if (!input.length) return;
    props.onEdit({ name: input });
    setEditing(false);
  };

  return (
    <div className="px-4 py-2 bg-background-medium rounded-md flex justify-between w-80 items-center">
      {editing ? (
        <div className="flex items-center gap-2">
          <TextInput
            dark
            value={input}
            onChange={({ target }) => setInput(target.value)}
            placeholder="Nom de la compétence"
            type="text"
          />
          <Button title="Confirmer" dark onClick={onEdit} size="small" kind="light" />
        </div>
      ) : (
        <>
          <div className="text-white">{skill.name}</div>
          <div className="flex gap-2 items-center">
            <PencilIcon
              height={16}
              width={16}
              onClick={() => setEditing(true)}
              className="text-primary-dark cursor-pointer"
            />
            <PopConfirm title="Êtes vous sûr de vouloir supprimer cette compétence ?" onValidate={() => onDelete()}>
              <XIcon height={16} width={16} className="cursor-pointer text-red-500" />
            </PopConfirm>
            <PopConfirm title="Êtes vous sûr de vouloir valider cette compétence ?" onValidate={() => onValidate()}>
              <CheckIcon height={16} width={16} className="cursor-pointer text-green-500" />
            </PopConfirm>
          </div>
        </>
      )}
    </div>
  );
};

export const SkillsPage: FunctionComponent = () => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [creatingSkill, setCreatingSkill] = useState<boolean>(false);

  useEffect(() => {
    fetchSkills({ validated: false }).then(setSkills);
  }, []);

  const onValidate = async (skill: Skill) => {
    await updateSkill(skill.id, { validated: true });
    setSkills((s) => s.filter((s) => s.id !== skill.id));
    toast.success("La compétence a bien été validée");
  };

  const onDelete = async (skill: Skill) => {
    await deleteSkill(skill.id);
    setSkills((s) => s.filter((s) => s.id !== skill.id));
    toast.success("La compétence a bien été supprimée");
  };

  const onEdit = async (id: Uuid, payload: SkillUpdate) => {
    const skill = await updateSkill(id, payload);
    const skillIndex = skills.findIndex((s) => s.id === id);
    const skillsUpdated = [...skills];
    skillsUpdated[skillIndex] = skill;
    setSkills(skillsUpdated);
    toast.success("La compétence a bien été mise à jour");
  };

  const onCreate = async (payload: NewSkill) => {
    await createSkill(payload);
    setCreatingSkill(false);
    toast.success("La compétence a bien été créée");
  };

  return (
    <div className="min-h-full w-full flex-grow bg-background-dark">
      <Header
        left={
          <div className="flex justify-end w-full">
            <Button
              icon={<PlusCircleIcon />}
              onClick={() => setCreatingSkill(true)}
              size="small"
              title="Ajouter une compétence"
            />
          </div>
        }
        center={<div className="text-white font-bold text-3xl">Skills</div>}
      />
      ;
      <div className="flex flex-col items-center justify-center ">
        <div className="max-w-[1600px] w-full flex flex-col items-center">
          <div className="flex flex-col w-fit  gap-3 px-5 py-3">
            {skills.map((s) => (
              <SkillElem
                key={s.id}
                skill={s}
                onValidate={() => onValidate(s)}
                onDelete={() => onDelete(s)}
                onEdit={(payload) => onEdit(s.id, payload)}
              />
            ))}
          </div>
        </div>
      </div>
      <Modal show={creatingSkill} onClose={() => setCreatingSkill(false)}>
        <SkillForm onCreate={onCreate} />
      </Modal>
    </div>
  );
};
