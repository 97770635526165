import { Tooltip } from "@getprorecrutement/getpro-design";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  CheckIcon,
  RefreshIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Category } from "../models/Category";
import { ActionTypes, ChangeRecord, getAction, getKind, isValidated } from "../models/changeRecord";
import { Hierarchy } from "../models/Hierarchy";
import { JobTitle, JobTitleUpdate, UnvalidatedJobTitle, UnvalidatedJobTitleUpdate } from "../models/JobTitle";
import { Uuid } from "../services/routes";

export const renderAction = (record: ChangeRecord) => {
  let action = getAction(record);
  if (!action) return "";
  if (action.Assign)
    return (
      <div className="flex justify-center items-center gap-5">
        <div>
          <div className="text-primary-light text-xs">
            {action.Assign.old.path
              .reverse()
              .map((c) => c.title)
              .join(" ➔ ")}
          </div>
          <div>{action.Assign.old.item.title}</div>
        </div>
        <div>
          <div> ➔ </div>
        </div>
        <div>
          <div className="text-primary-light text-xs">
            {action.Assign.new.path
              .reverse()
              .map((c) => c.title)
              .join(" ➔ ")}
          </div>
          <div>{action.Assign.new.item.title}</div>
        </div>
      </div>
    );
  if (action.Update) return action.Update.from.title;
  if (action.Delete) return action.Delete.title;
  if (action.Validate) return action.Validate.title;
};

export const UpdateRecordTooltip = ({
  from,
  to,
  categories,
  hierarchies,
}: {
  from: UnvalidatedJobTitle | JobTitle;
  to: UnvalidatedJobTitleUpdate | JobTitleUpdate;
  categories: { [key: Uuid]: Category };
  hierarchies: { [key: Uuid]: Hierarchy };
}) => {
  const labels = {
    title: "Titre",
    hierarchy_id: "Hiérarchie",
    category_id: "Catégorie",
    id: "Id",
  };

  const render = (key: keyof UnvalidatedJobTitle | JobTitle, value: any) => {
    if (key == "category_id") {
      return categories[value].title;
    } else if (key == "hierarchy_id") {
      return hierarchies[value].title;
    }
    return value;
  };

  return (
    <div>
      {Object.entries(to)
        .filter(([k, v]) => !!v && from[k as keyof JobTitle] != v)
        .map(([key, value]) => {
          return (
            <div key={key + value} className="bg-primary-light text-primary-lighter p-3 text-base rounded-xl max-w-lg">
              <div className=" font-bold">{labels[key as keyof JobTitle]}</div>
              <div className="ml-3 mt-3 flex gap-3 justify-center items-center">
                <div>{render(key as keyof JobTitle, from[key as keyof JobTitle] as string) || "null"}</div>
                <ArrowRightIcon height={18} width={18} />
                <div>{render(key as keyof JobTitle, value)}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const RecordRow = ({
  record,
  onRevert,
  onDelete,
  categories,
  hierarchies,
}: {
  record: ChangeRecord;
  categories: { [key: Uuid]: Category };
  hierarchies: { [key: Uuid]: Hierarchy };
  onRevert: (id: Uuid) => void;
  onDelete: (id: Uuid) => void;
}) => {
  const [actionType, setActionType] = useState<ActionTypes>();

  useEffect(() => {
    setActionType(getKind(record));
  }, [record]);

  const icon = () => {
    if (!actionType) return <></>;
    switch (actionType) {
      case ActionTypes.Assign:
        return <ArrowRightIcon height={24} width={24} className="text-blue-500" />;
      case ActionTypes.Delete:
        return <TrashIcon height={24} width={24} className="text-red-500" />;
      case ActionTypes.Update:
        return <RefreshIcon height={24} width={24} className="text-white" />;
      case ActionTypes.Validate:
        return <CheckIcon height={24} width={24} className="text-green-500" />;
    }
  };

  const more = () => {
    if (!actionType) return <></>;
    switch (actionType) {
      case ActionTypes.Update:
        const update = record.action.UnvalidatedJobTitle?.Update || record.action.JobTitle?.Update;
        return (
          (update && <UpdateRecordTooltip categories={categories} hierarchies={hierarchies} {...update} />) || <></>
        );
    }
    return <></>;
  };

  return (
    <div className=" my-5 border px-5 py-3 rounded-xl border-primary-light text-primary-bright">
      <div className="flex justify-between">
        <div className=" flex justify-center items-center gap-5">
          <div className="text-primary-light italic">{record.user_email}</div>
          {isValidated(record) && <div className="text-red-500">Titre validé</div>}
        </div>
        <div className="flex gap-3 justify-center items-center">
          <Tooltip title="Annuler">
            <XCircleIcon
              height={18}
              width={18}
              className="text-red-500 cursor-pointer"
              onClick={() => onRevert(record.id)}
            />
          </Tooltip>
          <Tooltip title="Valider">
            <CheckCircleIcon
              height={18}
              width={18}
              className="text-green-500 cursor-pointer"
              onClick={() => onDelete(record.id)}
            />
          </Tooltip>
        </div>
      </div>
      <div className="flex gap-5 mt-5 justify-between">
        <div className="flex gap-5">
          <Tooltip position="bottom" customRenderer={more}>
            {icon()}
          </Tooltip>

          <div className="w-max">{renderAction(record)}</div>
        </div>

        <div className="text-primary-light italic">{dayjs(record.created_at).fromNow()}</div>
      </div>
    </div>
  );
};
