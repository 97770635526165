import { Button, ColorType, Select, TextInput } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Category, CategoryTreeElem, constructCategoryTree } from "../models/Category";
import { CategoryTree } from "./CategoryTree";

export interface CategorySearchProps {
  categories: Category[];
  title?: string;
  filteredCategories?: CategoryTreeElem[];
  selectedCategory?: CategoryTreeElem;
  onSelect: (category: Category) => void;
}

export const CategorySearch: FunctionComponent<CategorySearchProps> = ({
  categories,
  title,
  onSelect,
  selectedCategory,
  filteredCategories: _filteredCategories,
}) => {
  const [search, setSearch] = useState<string>("");
  const [categoryTree, setCategoryTree] = useState<CategoryTreeElem[]>();
  const [filteredCategories, setFilteredCategories] = useState<CategoryTreeElem[]>();
  const [value, setValue] = useState<CategoryTreeElem>();

  const filterCategories = (categoryTree: CategoryTreeElem[]): CategoryTreeElem[] => {
    let filtered = categoryTree.reduce((acc, next) => {
      let filtered_category = { ...next, childs: filterCategories(next.childs) };
      if (
        filtered_category.category.title.toLowerCase().includes(search.toLowerCase() || "") ||
        filtered_category.childs.length !== 0
      )
        acc.push(filtered_category);
      return acc;
    }, [] as CategoryTreeElem[]);
    return filtered;
  };

  useEffect(() => {
    console.log(selectedCategory);
    setValue(selectedCategory);
  }, [selectedCategory]);

  let createTree = useCallback(() => {
    setCategoryTree(_filteredCategories ? _filteredCategories : constructCategoryTree(categories));
  }, [categories, _filteredCategories]);

  useEffect(() => {
    if (value) {
      setSearch(value.category.title);
    }
  }, [value]);

  useEffect(() => {
    createTree();
  }, [createTree]);

  useEffect(() => {
    if (categoryTree) {
      setFilteredCategories(filterCategories(categoryTree));
    }
  }, [search, categoryTree]);

  const renderSelectValue = (opened: boolean, _: any) => {
    const textInputClasses = classNames("  ", {
      "border-b-0 rounded-b-none": opened,
    });
    const valueClasses = classNames("border overflow-hidden  hover:border-primary-dark rounded-full px-2", {
      "border-b-0 rounded-b-none rounded-2xl border-primary-dark":
        opened && filteredCategories && filteredCategories.length > 0,
      "border-primary-light": !opened || !filteredCategories?.length,
    });

    return (
      <div className={valueClasses} onClick={(ev) => opened && ev.stopPropagation()}>
        <TextInput
          className={textInputClasses}
          bordered={false}
          placeholder="Recherchez une catégorie"
          onChange={({ target }) => setSearch(target.value)}
          value={search}
          type="text"
        />
      </div>
    );
  };

  return (
    <div>
      <div className="font-bold">{title || "Sélectionner une catégorie:"}</div>
      <div className="bg-white flex gap-5 mt-10">
        <Select
          bordered
          valueRenderer={renderSelectValue}
          rounded
          type="single"
          options={filteredCategories || []}
          value={value}
          onChange={() => {}}
          label="Catégorie"
          getKey={(elem) => elem.category.id}
          optionRenderer={(category, index, onClick) => (
            <CategoryTree
              key={index}
              selectedParent={undefined}
              category={category}
              onClick={(elem) => {
                console.log("hein ?");
                onClick();
                setValue(elem);
              }}
            />
          )}
          getLabel={(elem) => {
            return elem.category.title;
          }}
        />
        <Button
          title="Confimer"
          size="small"
          colorType={ColorType.Content}
          disabled={!value}
          onClick={() => {
            value && onSelect(value.category);
          }}
        />
      </div>
    </div>
  );
};
