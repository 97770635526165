import { FC, useState } from "react";
import { Button, ColorType, TextInput } from "@getprorecrutement/getpro-design";

export interface EditTitleFormProps {
  onFinished?: (value: string) => void;
  text?: string;
}

export const EditTitleForm: FC<EditTitleFormProps> = ({ text, onFinished }) => {
  const [value, setValue] = useState<string>(text || "");

  return (
    <div className="p-5">
      <p className="mb-5 text-lg font-bold">Editer le nom</p>
      <TextInput
        size="medium"
        className="w-max"
        type="text"
        value={value}
        placeholder="nom"
        onChange={({ target }) => {
          setValue(target.value);
        }}
      />
      <div className="flex mt-5 justify-end gap-5">
        <Button size="small" title="Enregistrer" colorType={ColorType.Content} onClick={() => onFinished?.(value)} />
      </div>
    </div>
  );
};
