import { Button, ColorType, Select, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useState } from "react";
import { Hierarchy } from "../models/Hierarchy";
import { Uuid } from "../services/routes";

export interface JobTitleFormProps {
  hierarchies: Hierarchy[];
  onConfirm: (payload: { title: string; hierarchy_id: Uuid }) => void;
}

export const JobTitleForm: FunctionComponent<JobTitleFormProps> = ({ hierarchies, onConfirm }) => {
  const [selectedHierarchy, setSelectedHierarchy] = useState<Hierarchy>();
  const [title, setTitle] = useState<string>("");

  const isValid = () => {
    return title.length > 0 && !!selectedHierarchy;
  };

  const submit = () => {
    if (!isValid()) return;

    onConfirm({
      title,
      hierarchy_id: selectedHierarchy?.id!,
    });
  };

  return (
    <div>
      <div>Nouveau Titre:</div>
      <TextInput
        className="mt-5 bg-white"
        value={title}
        onChange={({ target }) => setTitle(target.value)}
        label="Nom du poste"
        placeholder="Développeur Full Stack"
        type="text"
      />
      <Select
        rounded
        bordered
        value={selectedHierarchy}
        options={hierarchies}
        className="mt-5 bg-white"
        onChange={setSelectedHierarchy}
        type="single"
        getKey={(h) => h.id}
        getLabel={(h) => h.title}
        label="Niveau managérial"
      />

      <div className="flex justify-end mt-5">
        <Button disabled={!isValid()} colorType={ColorType.Content} onClick={submit} title="Créer" />
      </div>
    </div>
  );
};
