import httpClient from "../services/httpClient";
import ROUTES, { Uuid } from "../services/routes";

export interface Skill {
  id: Uuid;
  name: string;
}

export interface SkillUpdate {
  name?: string;
  validated?: boolean;
}

export interface SkillFilters {
  validated?: boolean;
}

export interface NewSkill {
  name: string;
  validated?: boolean;
}

export const fetchSkills = (filters?: SkillFilters) => httpClient.req(ROUTES.FETCH_SKILLS(filters));
export const updateSkill = (id: Uuid, payload: SkillUpdate) => httpClient.req(ROUTES.UPDATE_SKILL(id, payload));
export const deleteSkill = (id: Uuid) => httpClient.req(ROUTES.DELETE_SKILL(id));
export const createSkill = (newSkill: NewSkill) => httpClient.req(ROUTES.CREATE_SKILL(newSkill));
