import httpClient from "../services/httpClient";
import ROUTES, { Uuid } from "../services/routes";

export interface Hierarchy {
  id: string;
  title: string;
  color: string;
}

export interface NewHierarchy {
  title: string;
  color: string;
}

export const createHierarchy = (hierarchy: NewHierarchy) => httpClient.req(ROUTES.CREATE_HIERARCHY(hierarchy));
export const deleteHierarchy = (id: Uuid) => httpClient.req(ROUTES.DELETE_HIERARCHY(id));
export const updateHierarchy = (hierarchy: Hierarchy) => httpClient.req(ROUTES.UPDATE_HIERARCHY(hierarchy));
export const fetchHierarchies = () => httpClient.req(ROUTES.FETCH_HIERARCHIES());
