import httpClient from "../services/httpClient";
import ROUTES, { Uuid } from "../services/routes";

export interface AuthResponse {
  token: string;
  jwt: string;
}

export interface JWT {
  exp: number;
  email: string;
  first_name: string;
  last_name: string;
  license_id?: Uuid;
  superadmin: boolean;
  sub: Uuid;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface OauthPayload {
  state: string;
  code: string;
  redirect_url: string;
  scopes: string;
}

export const login = (data: LoginPayload) => httpClient.req(ROUTES.LOGIN(data));
