import { Button, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useState } from "react";
import { Category, NewCategory } from "../models/Category";

export interface AddingCategoryFormProps {
  fromCategory: Category;
  onFinish: (payload: NewCategory) => void;
}

export const AddingCategoryForm: FunctionComponent<AddingCategoryFormProps> = ({ fromCategory, onFinish }) => {
  const [input, setInput] = useState<string>("");

  return (
    <div>
      <div>Nouvelle catégorie</div>
      <div>Catégorie parente: {fromCategory.title}</div>
      <TextInput
        value={input}
        type="text"
        onChange={({ target }) => setInput(target.value)}
        placeholder="Nom de la catégorie"
        label="Catégorie"
      />
      <div className="flex justify-end-end">
        <Button
          disabled={input.length === 0}
          title="Confirmer"
          onClick={() => {
            onFinish({
              parent_id: fromCategory.id,
              title: input,
            });
          }}
        />
      </div>
    </div>
  );
};
