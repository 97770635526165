import { Button, Modal, Select } from "@getprorecrutement/getpro-design";
import { ArrowRightIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Category, fetchCategories } from "../models/Category";
import { fetchJobTitles, JobTitleResponse } from "../models/JobTitle";
import { createTestAttempt, NewTestAttempt } from "../models/testAttempt";
import store from "../services/store";

interface TestResult {
  valid: boolean;
  sent_category_path?: string;
}

interface TestAnswer {
  test_result: TestResult;
  correct_path?: string;
}

export const Test: FunctionComponent<{}> = () => {
  const [jobTitles, setJobTitles] = useState<JobTitleResponse[]>();
  const [categories, setCategories] = useState<Category[]>();
  const [selectedJobTitle, setSelectedJobTitle] = useState<JobTitleResponse>();
  const [selectedCategories, setSelectedCategories] = useState<Category[]>();
  const [childCategories, setChildCategories] = useState<Category[]>();
  const [result, setResult] = useState<TestAnswer>();

  const update = useCallback(() => {
    setResult(undefined);
    setSelectedCategories(undefined);
    Promise.all([fetchCategories(), fetchJobTitles()]).then(([categories, jobTitles]) => {
      setJobTitles(jobTitles);
      setCategories(categories);
      setChildCategories(categories.filter((cat) => !cat.parent_id));

      const sorted = jobTitles.sort((a, b) => a.test_count - b.test_count);
      const filtered = sorted.filter((e) => e.test_count === sorted[0].test_count);

      if (jobTitles.length > 0) {
        let pick = Math.round(Math.random() * (filtered.length - 1));
        setSelectedJobTitle(filtered[pick]);
      }
    });
  }, []);

  useEffect(() => {
    update();
  }, [update]);

  const getChildCategories = (selectedCategories: Category[]) => {
    const lastCategoryId = selectedCategories?.[selectedCategories?.length - 1]?.id;
    return (categories || []).filter((category) =>
      lastCategoryId ? category.parent_id === lastCategoryId : !category.parent_id
    );
  };

  const computeResults = async () => {
    const lastCategoryId = selectedCategories?.[selectedCategories?.length - 1]?.id;

    if (!selectedJobTitle) return;

    const result: TestResult = { valid: lastCategoryId === selectedJobTitle?.category_id };

    if (!result.valid) {
      result.sent_category_path = selectedCategories?.map((elem) => elem.title).join(" -> ");
      let lastCategory = categories?.find((cat) => selectedJobTitle.category_id === cat.id);
      if (!lastCategory) return;
      let path: Category[] = [lastCategory];
      while (path[path.length - 1].parent_id) {
        let category = categories?.find((cat) => path[path.length - 1].parent_id === cat.id);
        if (!category) break;
        path.push(category);
      }

      setResult({
        test_result: result,
        correct_path: path
          .map((elem) => elem.title)
          .reverse()
          .join(" -> "),
      });
    } else {
      setResult({ test_result: result });
    }
    await createTestAttempt({ ...result, job_title_id: selectedJobTitle.id, user_id: store.state.JWT?.sub || "" });
  };

  const reset = () => {
    update();
  };

  const goBack = () => {
    const selected = selectedCategories?.slice(0, -1) || [];
    setChildCategories(getChildCategories(selected));
    setSelectedCategories(selected);
  };

  console.log(selectedJobTitle);

  return (
    <div className="p-10">
      <div className="flex gap-3 justify-center items-center text-3xl">
        <div>Essayez de catégoriser :</div>
        <div className="p-5 rounded-xl bg-primary-darker text-white">{selectedJobTitle?.title}</div>
      </div>
      <div className="w-full h-full bg-primary-darker mt-10 p-10 rounded-3xl text-white">
        <div className="text-3xl">Sélectonner la catégorie</div>
        <div className="flex gap-8 items-center mt-10">
          {selectedCategories?.map((cat, index) => {
            const res = [
              <div key={cat.id} className="px-3 py-1 border-primary-bright rounded-3xl border">
                {cat.title}
              </div>,
            ];
            if (index < selectedCategories.length - 1) {
              res.push(<ArrowRightIcon key={index} className="h-8 w-8" />);
            }
            return res;
          })}
        </div>
        <div className="p-5 bg-primary-dark mt-5 rounded-xl">
          <div className="text-xl">Catégorie plus précise (optionel): </div>
          <div className="flex flex-wrap gap-3 p-5">
            {childCategories &&
              childCategories.length > 0 &&
              childCategories.map((cat) => {
                return (
                  <Button
                    key={cat.id}
                    title={cat.title}
                    onClick={() => {
                      const selected = [...(selectedCategories || []), cat];
                      setChildCategories(getChildCategories(selected));
                      setSelectedCategories(selected);
                    }}
                  />
                );
              })}
          </div>
        </div>
        <div className="flex w-full justify-end mt-5 gap-2">
          {selectedCategories && selectedCategories.length > 0 && (
            <Button color="light" title="Retour" onClick={goBack} />
          )}
          {selectedCategories && selectedCategories.length > 0 && (
            <Button color="light" title="Confirmer" onClick={computeResults} />
          )}
        </div>
      </div>

      <Modal show={result !== undefined} onClose={reset}>
        <div className="flex flex-col justify-center items-center gap-3 w-80">
          {result?.test_result.valid ? (
            <div className="text-green-500 text-5xl">Correct</div>
          ) : (
            <div className="text-red-500 text-5xl">Incorrect</div>
          )}
          {result?.test_result.valid ? (
            <CheckCircleIcon className="text-green-500 h-32 w-23" />
          ) : (
            <XCircleIcon className="text-red-500 h-32 w-32" />
          )}
          {!result?.test_result.valid && (
            <div className="text-red-500 text-2xl text-center">
              Catégorie attendue: <div className="font-bold">{result?.correct_path}</div>
            </div>
          )}
          <div className="mt-5">
            <Button title="Réessayer" onClick={reset} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
