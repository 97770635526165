import { FunctionComponent, useState } from "react";
import { NewSkill } from "../models/skill";
import { Button, TextInput } from "@getprorecrutement/getpro-design";

export interface SkillFormProps {
  onCreate: (payload: NewSkill) => void;
}

export const SkillForm: FunctionComponent<SkillFormProps> = ({ onCreate }) => {
  const [input, setInput] = useState<string>("");

  const submit = () => {
    if (!input.length) return;
    onCreate({ name: input, validated: true });
  };

  return (
    <div className="bg-inherit flex flex-col gap-5">
      <div className="font-bold text-xl">Nouvelle compétence</div>
      <TextInput
        value={input}
        onChange={({ target }) => setInput(target.value)}
        type="text"
        placeholder="Rust"
        label="Nom de la compétence"
      />
      <div className="flex justify-end">
        <Button title="Confirmer" size="small" onClick={submit} />
      </div>
    </div>
  );
};
