import { Button, ColorType, Modal, Select, TextInput } from "@getprorecrutement/getpro-design";
import { FunctionComponent, useEffect, useState } from "react";
import { Category, CategoryTreeElem } from "../models/Category";
import { Hierarchy } from "../models/Hierarchy";
import { JobTitle, JobTitleResponse } from "../models/JobTitle";
import { Uuid } from "../services/routes";
import { CategorySearch } from "./CategorySearch";
import { CategoryTreeSelector } from "./CategoryTreeSelector";
import { JobTitleSearch } from "./JobTitleSearch";

export interface UpdateJobTitleFormProps {
  jobTitle: { title: JobTitleResponse; parent: CategoryTreeElem };
  jobTitles: JobTitle[];
  categories: CategoryTreeElem[];
  hierarchies: Hierarchy[];
  onAssign: (newId: Uuid, keepAsSynonym: boolean) => void;
  onFinish: (jobTitle: JobTitleResponse) => void;
}

export const UpdateJobTitleForm: FunctionComponent<UpdateJobTitleFormProps> = ({
  jobTitle,
  categories,
  jobTitles,
  hierarchies,
  onFinish,
  onAssign,
}) => {
  const [input, setInput] = useState<string>("");
  const [showReassign, setShowReassign] = useState<boolean>(false);
  const [parentCategory, setParentCategory] = useState<CategoryTreeElem>();
  const [selectedHierarchy, setSelectedHierarchy] = useState<Hierarchy>();

  useEffect(() => {
    if (jobTitle) {
      setInput(jobTitle.title.title);
      setParentCategory(jobTitle.parent);
      setSelectedHierarchy(hierarchies.find((h) => h.id === jobTitle.title.hierarchy_id));
    }
  }, [jobTitle, hierarchies]);

  return (
    <div>
      <div className="bg-white">
        <div className="text-xl font-bold mb-10 text-center">{jobTitle.title.title}</div>
        {showReassign ? (
          <div>
            <JobTitleSearch jobTitles={jobTitles} onConfirm={(res, keepAsSynonym) => onAssign(res.id, keepAsSynonym)} />
            <div className="mt-10 justify-end flex">
              <Button
                title="Retourner à l'édition"
                size="small"
                colorType={ColorType.Content}
                onClick={() => setShowReassign(false)}
              />
            </div>
          </div>
        ) : (
          <div>
            <CategoryTreeSelector
              title="Catégorie parente"
              categories={categories}
              onChange={setParentCategory}
              value={parentCategory}
            />
            <Select
              options={hierarchies}
              value={selectedHierarchy}
              className="mt-5 bg-white"
              bordered
              rounded
              label="Niveau Managérial"
              onChange={setSelectedHierarchy}
              getKey={(h) => h.id}
              getLabel={(h) => h.title}
              type="single"
            />
            <TextInput
              label="Nom de la catégorie"
              className="mt-5"
              value={input}
              onChange={({ target }) => setInput(target.value)}
              placeholder="Nom de la catégorie"
              light
              type="text"
            />

            <div className="flex justify-between mt-10 gap-5">
              <Button
                title="Assigner a un titre existant"
                size="small"
                colorType={ColorType.Content}
                onClick={() => setShowReassign(true)}
              />
              <Button
                title="Confirmer"
                disabled={input.length === 0 && !parentCategory}
                onClick={() =>
                  parentCategory &&
                  selectedHierarchy &&
                  onFinish({
                    ...jobTitle.title,
                    title: input,
                    category_id: parentCategory.category.id,
                    hierarchy_id: selectedHierarchy.id,
                  })
                }
                colorType={ColorType.Content}
                size="small"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
