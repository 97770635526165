import { Button, Checkbox, ColorType, PopConfirm, Select } from "@getprorecrutement/getpro-design";
import classNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { JobTitle } from "../models/JobTitle";

export interface JobTitleSearchProps {
  jobTitles: JobTitle[];
  onConfirm: (jobTitle: JobTitle, keepAsSynonym: boolean) => void;
}

export const JobTitleSearch: FunctionComponent<JobTitleSearchProps> = ({ jobTitles, onConfirm }) => {
  const [search, setSearch] = useState<string>("");
  // TODO do the search in back end
  const [filteredJobTitle, setFilteredJobTitle] = useState<JobTitle[]>([]);
  const [value, setValue] = useState<JobTitle>();
  const [keepAsSynonym, setKeepAsSynonym] = useState<boolean>(false);

  useEffect(() => {
    setKeepAsSynonym(false);
  }, [jobTitles]);

  useEffect(() => {
    setFilteredJobTitle(jobTitles.filter((title) => title.title.toLowerCase().includes(search.toLowerCase())));
  }, [jobTitles, search]);

  return (
    <div>
      <div className="font-bold">Sélectionner un titre de poste à assigner</div>
      <div className="flex gap-5 bg-white mt-5">
        <Select
          className="w-[350px]"
          type="single"
          value={value}
          label={"Titres de poste"}
          rounded={true}
          onSearch={setSearch}
          options={filteredJobTitle}
          bordered={true}
          optionRenderer={(value, index, onClick) => {
            return (
              <div className="mx-3 hover:font-bold" onClick={onClick}>
                {value.title}
              </div>
            );
          }}
          getKey={(title: JobTitle) => title.id}
          getLabel={(title: JobTitle) => title.title}
          onChange={setValue}
        />

        <Button
          title="Assigner"
          size="small"
          colorType={ColorType.Content}
          disabled={!value}
          onClick={() => value && onConfirm(value, keepAsSynonym)}
        />
      </div>
      <div className="flex items-center gap-2 mt-10">
        <Checkbox dark={true} value={keepAsSynonym} onChange={setKeepAsSynonym} />
        <div>Créer un synonyme</div>
      </div>
    </div>
  );
};
